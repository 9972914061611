<template>
  <line-chart
    title="Meilleures sous-thématiques"
    subTitle="sur la période choisie"
    :colors="colors"
    :series="series"
    :labels="labels"
    :yAxis="yAxis"
    :stroke="stroke"
    :loading="loading"
    :classNames="classNames"
  ></line-chart>
</template>

<script>
import useAxios from "@/hooks/useAxios";
import { chartColors } from "@/utils/constants";
import { formatNumberCompact } from "@/utils/formatting";

import LineChart from "@/components/common/charts/LineChart";

export default {
  name: "TopSubThematicsOverPeriod",
  components: {
    LineChart,
  },
  props: {
    classNames: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      loading: false,
      series: [
        /* {
          name: "CA",
          data: [],
        }, */
      ],
      labels: [],
      colors: chartColors,
      stroke: {
        show: true,
        curve: "smooth",
        width: 2,
      },
      yAxis: [
        /* {
          title: {
            text: "Revenue",
          },
          seriesName: "Revenue",
          min: 0,
          forceNiceScale: true,
          labels: {
            formatter: (val) => formatNumber(val),
          },
        }, */
      ],
    };
  },
  setup() {
    const { axiosGet } = useAxios();

    return {
      axiosGet,
    };
  },
  methods: {
    async getData() {
      if (
        this.applyFilters &&
        !this.loading &&
        this.startDate &&
        this.startDate.length === 10 &&
        this.endDate &&
        this.endDate.length === 10 &&
        this.indicator
      ) {
        this.loading = true;
        const { data } = await this.axiosGet(
          "/audience/acpm/top-subthematics-for-period",
          {
            start_date: this.startDate,
            end_date: this.endDate,
            indicator: this.indicator,
            business_ids: this.businesses.join(","),
            site_ids: this.sites.join(","),
            thematic_ids: this.thematics.join(","),
            subthematic_ids: this.subThematics.join(","),
            limit: 6,
          }
        );

        const series = {};
        const labels = [];
        for (const entry of data) {
          if (!labels.includes(entry.date)) {
            labels.push(entry.date);
          }
          if (!Object.keys(series).includes(entry.name)) {
            series[entry.name] = {
              name: entry.name,
              data: [],
            };
          }
          series[entry.name].data.push(entry.value);
        }

        this.series = Object.values(series);
        const label =
          this.indicatorList.find((item) => item.id === this.indicator)?.name ??
          "";
        this.yAxis = [
          {
            title: {
              text: label,
            },
            seriesName: label,
            min: 0,
            forceNiceScale: true,
            labels: {
              formatter: (val) => formatNumberCompact(val),
            },
          },
        ];
        this.labels = labels;

        this.loading = false;

        // Force clicking on button again to re-run that request :
        // (we don't call this dispatch earlier to be sure, as state is async, that all listening components could run their requests)
        this.$store.dispatch("common/updateApplyFilters", false);
      }
    },
  },
  computed: {
    applyFilters() {
      return this.$store.getters["common/getApplyFilters"];
    },
    startDate() {
      return this.$store.getters["audience/getDates"][0];
    },
    endDate() {
      return this.$store.getters["audience/getDates"][1];
    },
    indicator() {
      return this.$store.getters["audience/getAcpmIndicator"];
    },
    indicatorList() {
      return this.$store.getters["audience/getAcpmIndicatorList"];
    },
    businesses() {
      return this.$store.getters["audience/getAcpmBusinesses"];
    },
    sites() {
      return this.$store.getters["audience/getAcpmSites"];
    },
    thematics() {
      return this.$store.getters["audience/getAcpmThematics"];
    },
    subThematics() {
      return this.$store.getters["audience/getAcpmSubThematics"];
    },
  },
  watch: {
    applyFilters() {
      this.getData();
    },
  },
};
</script>
